import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Formik, Form, Field, useField } from 'formik';
import { Text, Header, Button } from '../components';
import { Face } from '../components/face';
import Layout from '../components/layout';
import Meta from '../components/meta';
import clsx from 'clsx';
import { get} from '../services/api';
import useLocalStorage from '../hooks/useLocalstorage';

const CharField = ({ name, focusNext, next, className, triggerFaceReaction }) => {
	const [field, , helpers] = useField(name);

	const handleChange = e => {
		helpers.setValue(e.target.value);
		if(e.target.value.length && next) {
			focusNext(next);
		}
		triggerFaceReaction();
	};

	return <Field
		onChange={handleChange}
		value={field.value}
		autoComplete="off"
		maxLength="1"
		type="text"
		name={name}
		className={clsx(className, 'text-center border-collapse h-84 w-70 rounded-2xl text-64 font-nove')}
	/>;
};

const Page = () => {
	const [showFetchError, setShowFetchError] = useState(false);
	const [isAngry, setIsAngry] = useState(false);
	const [faceReaction, setFaceReaction] = useState(0);
	const [,setTheme] = useLocalStorage('FKN_Theme', '');
	const [ ,setSktCode] = useLocalStorage('FKN_SKT');
	const handleSubmit = async ({...values}) => {
		setShowFetchError(false);
		const sktCode = (values.char1 + values.char2 + values.char3).toLowerCase();
		const data = await get(`klasse/${sktCode}`);
		if (data?.skole && data?.klasse && data?.skt_kode && data?.tema) {
			setTheme(data.tema);
			setSktCode(data.skt_kode);
			navigate('/explore');
		} else {
			setShowFetchError(true);
		}
	};

	const focusNext = next => {
		const field = document.querySelector(`input[name=${next}]`);
		if(field !== null) {
			field.focus();
		}
	};

	const triggerFaceReaction = () => {
		setFaceReaction((prev) => prev + 1);
	};
	

	return (
		<Layout>
			<Meta title="Velkommen elev" description="Indtast din kode her"/>
			<Header color="#fff" hasBack={false}/>
			<div className="min-h-screen bg-secondary">
				<div className="flex flex-col items-center justify-center h-screen mx-auto max-w-grid">
					<div className="w-330">
						<Face isAngry={isAngry} faceReaction={faceReaction} />
					</div>
					<Text className="mt-64 mb-40" type="body1">Indtast din kode på 3 bogstaver her</Text>
					<Formik initialValues={{ char1: '', char2: '', char3: '' }} onSubmit={handleSubmit}>
						{({ values }) => (
							<Form>
								<div className="flex justify-center">
									<CharField triggerFaceReaction={() => triggerFaceReaction()} name="char1" focusNext={focusNext} next="char2" className="focus:outline-none"/>
									<CharField triggerFaceReaction={() => triggerFaceReaction()} name="char2" focusNext={focusNext} next="char3" className="mx-32 focus:outline-none"/>
									<CharField triggerFaceReaction={() => triggerFaceReaction()} name="char3" focusNext={focusNext} className="focus:outline-none"/>
								</div>
								<p 
									onMouseEnter={() => showFetchError ? setIsAngry(true) : null} 
									onMouseLeave={() => setIsAngry(false)} 
									className="mt-24 font-sans font-bold text-center min-h-24">
									{showFetchError ? 'Øv, koden virkede ikke 😥 Prøv igen!' : ''}
								</p>
								<div className="flex items-center justify-center mt-16">
									<Link to="/" className="mr-36 font-playfair text-15">Tilbage</Link>
									<Button type="submit" variant="submitButton" colors='bw' disabled={!(values.char1.length * values.char2.length * values.char3.length)}>Ok</Button>
								</div>
							</Form>
						)}
					</Formik>
					
				</div>
			</div>
		</Layout>
	);
};

export default Page;
